import React from 'react';
import Layout3 from '../components/layout4';


const HomePage = (props: any) => {

  return (
    <Layout3>
        <div style={{margin: "30px"}}>

          <h1>最新情報</h1>

<hr/>
          <h2>Ver2.0  - 2021/ 9/（仮）</h2><br/>

          <h3>
            １学期中にいただいたご意見をもとに、機能の改善を行いました。
          </h3>
            <p>
              <li>タイムバーのデザインを変更し、消すボタンを統合、自動ボタンを追加しました。</li>
              <li>棒グラフをダブルクリックすると、ズームします。</li>
              <li>地面をダブルクリックすると、視点が戻ります。（視点を戻すボタンは不要に）</li>
              <li>場所を確認するボタンについて、地面に近づいている時のみ動作するようにしました。</li>
              <li>凡例に、詳細を見るボタンを追加しました。</li>
              <li>１画面での表示を優先するよう組み換え、凡例とTRY!を折り畳めるようにしました。</li>
              <li>スマホ等の解像度の低い端末にも対応しました。</li>
              <li>その他、全体にブラッシュアップを行っています。</li>
            </p>
            <p>
              <li>引き続き、ご利用の皆様からの改善のご提案をお待ちしています。</li>
            </p>

<hr/>
          <h2>Ver1.0  - 2021/ 5/21</h2><br/>

          <h3>
            「ジオグラフ」を公開しました。
          </h3>
          <p>
            <li>使い方を解説する動画を追加し、デザインを修正しました。</li>
            <li>ご利用の皆様からの改善のご提案をお待ちしています。</li>
          </p>

<hr/>
          <h2>Ver0.9  - 2021/ 4/19</h2><br/>

          <h3>
            KMLダウンロードボタンの機能を強化しました。
          </h3>
          <p>
            <li>各年のデータがスムーズに切り替わるようになりました。</li>
          </p>

          {/* <h3>
            そのほか、微修正を行なっております。
          </h3>
          <p>
            <li>FAQページの画像を差し替えました。
            </li>
            <li>表示の最適化を行いました。
            </li>
          </p> */}

<hr/>
          <h2>Ver0.8  - 2021/ 4/ 9</h2><br/>

          <h3>
            ジオグラフを試験公開しました。
          </h3>
          {/* <p>
            <li>本サイトの概要については、<a href="/saas"><b>ジオグラフの使い方</b></a>からご確認ください。
            </li>
          </p> */}




      </div>
    </Layout3>
  );
};

export default HomePage;

